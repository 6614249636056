import { PropOptions } from 'vue'
import { ILink } from '~/models/base/Link'
import { IPaymentMethod } from '~/models/components/PaymentMethod'

export const hasActivePromotion = {
  type: Boolean,
  default: false,
}
export const image = {
  type: [Object, String],
  default: () => ({}),
}
export const imageMobile = {
  type: [Object, String],
  default: () => ({}),
}
export const title = {
  type: String,
  required: true,
}
export const description = {
  type: String,
  default: '',
}
export const logo = {
  type: [Object, String],
  default: () => ({}),
}

export const category = {
  type: Object,
  default: () => ({}),
}

export const paymentMethodsPage = {
  type: Object,
  default: () => ({}),
} as PropOptions<ILink | Record<string, unknown>>

export const paymentMethods = {
  type: Array,
  default: () => [],
} as PropOptions<IPaymentMethod[] | []>

export const uspsConfig = {
  type: Array,
  default: () => [],
}

export const isRtr = {
  type: Boolean,
  default: false,
}
