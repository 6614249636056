
import { mapGetters } from 'vuex'
import {
  title,
  image,
  imageMobile,
  description,
  logo,
  hasActivePromotion,
  category,
} from './props'
import BrandBannerPaymentMethods from './brand-banner-payment-methods.vue'
import { uspsConfig } from '~/enums/usps-config'
import { IPaymentMethod } from '~/models/components/PaymentMethod'
import { BreadcrumbsTrustpilotBar } from '~/components/organisms'
import { UiImage } from '~/components/atoms'
import { BannerImage, MainTitle } from '~/components/molecules'
import { Container, Row, Column } from '~/components/grid'

export default {
  components: {
    BannerImage,
    MainTitle,
    Container,
    Row,
    Column,
    UiImage,
    BrandBannerPaymentMethods,
    BreadcrumbsTrustpilotBar,
  },

  props: {
    hasActivePromotion,
    image,
    imageMobile,
    title,
    description,
    logo,
    category,
    showPaymentMethods: {
      type: Boolean,
      default: true,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    showTrustPilot: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      uspsConfig,
    }
  },
  computed: {
    ...mapGetters('products', ['someProductsHaveRTRRedeemType']),
    ...mapGetters('contentful', [
      'paymentMethods',
      'paymentMethodsPage',
      'sharedPaymentMethods',
    ]),
    ...mapGetters('context', [
      'trustpilotLocale',
      'locale',
      'direction',
      'useRecommerce',
      'isGlobalMarketplace',
      'marketplacePrefix',
      'isLocalMarketplace',
    ]),
    paymentMethodsFormatted(): IPaymentMethod[] {
      return this.isGlobalMarketplace
        ? this.sharedPaymentMethods
        : Object.values(this.paymentMethods)
    },
    responsiveImage() {
      return {
        desktop: this.image,
        mobile: this.imageMobile,
      }
    },
    categoryCrumb() {
      if (this.category && Object.keys(this.category).length) {
        return {
          label: this.category.name,
          title: this.category.name,
          url: `/${this.category.slug}`,
        }
      }

      return {}
    },
    customCrumbs() {
      const fullCrumb = [{}]
      // Add brand category to the breadcrumbs
      if (this.category && Object.keys(this.category).length) {
        const categoryCrumbPiece = {
          label: this.category.name,
          title: this.category.name,
          url: `/${this.category.slug}`,
        }
        fullCrumb.push(categoryCrumbPiece)
      }

      // Add brand page to the breadcrumbs
      const productCrumbPiece = {
        label: this.title,
        title: this.title,
      }
      fullCrumb.push(productCrumbPiece)
      fullCrumb.splice(0, 1)
      return fullCrumb
    },
  },
}
