
import { paymentMethods, uspsConfig, paymentMethodsPage } from './props'
import { Usps, Icon } from '~/components/atoms'
import { PaymentMethods } from '~/components/molecules'
import { Container, Row, Column } from '~/components/grid'

export default {
  components: {
    Container,
    Row,
    Usps,
    Column,
    PaymentMethods,
    Icon,
  },
  props: {
    paymentMethods,
    uspsConfig,
    paymentMethodsPage,
  },
  data() {
    return {
      isPaymentMethodVisible: true,
    }
  },
  methods: {
    handleClose() {
      this.isPaymentMethodVisible = false
    },
  },
}
